.app {
  height: 100vh;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: auto;
  padding: 10px;
  position: relative;
}

.slider {
  /* margin-top: -2.5rem; */
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-template-rows: repeat(1, min-content);
  gap: 20px 30px;
  align-items: center;
  justify-items: center;
}

.image {
  display: block;
  width: 80vmin;
  object-fit: cover;
}

.arrow {
  color: black;
  font-size: 2.5rem;
  z-index: 1;
  cursor: pointer;
}

.arrow.left {
  color: darkgrey;
}

.arrow.right {
  color: darkgray;
}

.title {
  position: absolute;
  top: calc(((100vh - 80vmin) / 2) / 2 - 0.9rem);
  margin: auto;
  font-size: 1.8rem;
  line-height: 1.1;
  text-align: center;
}

@media screen and (max-width: 570px) {
  .slider {
    grid-template-columns: repeat(1, min-content);
    grid-template-rows: repeat(2, min-content);
    gap: 20px 0;
  }

  .title {
    font-size: 1.6rem;
    top: calc(((100vh - 80vmin) / 2) / 2 - 0.9rem);
    grid-column-start: 1;
  }

  .arrow {
    font-size: 2.3rem;
  }

  .arrow.left {
    grid-column-start: 1;
    grid-row-start: 3;
    margin-left: -100px;
  }

  .arrow.right {
    grid-column-start: 1;
    grid-row-start: 3;
    margin-left: 100px;
  }
}

@media screen and (orientation: portrait) {
  .image {
    width: 95vmin;
  }

  .title {
    top: calc(((100vh - 95vmin) / 2) / 2 - 1.8rem);
  }

  .slider {
    grid-template-rows: repeat(2, min-content);
    gap: 20px 0;
  }
}

@media screen and (orientation: landscape) and (max-height: 450px) {
  .image {
    width: 75vmin;
  }

  .slider {
    gap: 10px 0;
  }
}
